import React, { useState, useEffect } from 'react';
import { Loader } from '@trellixio/roaster-coffee';

const ProgressiveLoader = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    // We dynamically increase the value of progress to update the Loader width
    // this give the effect of a smooth loading animation
    // starting from 0 and ending at 99% of the Loader width
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        const diff = Math.random() * 10; // random value add to Loader width to show fill effect
        return Math.min(oldProgress + diff, 99); // here we define Loader width limit to 99%
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return <Loader percentage={progress} />;
};

export default ProgressiveLoader;
