const REACT_APP_BEANS_ENV = process.env.REACT_APP_BEANS_ENV || 'dev';

export const IS_PROD_ENV = REACT_APP_BEANS_ENV === 'prod';

const getByEnv = (beta, prod) => {
  return IS_PROD_ENV ? prod : beta;
};

export const API_BASE_URL = getByEnv('https://api.bns.re/v3/', 'https://api.stem.trybeans.com/v3/');
export const CONNECT_BASE_URL = getByEnv('https://connect.bns.re', 'https://connect.trybeans.com');

export const SENTRY_DSN = getByEnv(null, 'https://89d92892f3eb46f3a50c6b4007feac00@sentry.io/1196229');

export const INSPECTLET_ID = getByEnv(null, '311992013');

export const FB_PIXEL_ID = getByEnv(null, '3292533954139544');

export const AMPLITUDE_ID = getByEnv('4821b2ff603610d0368ac58553424136', '6e71ad36598831877f010cd6d57dcb8b');

export const GOOGLE_ANALYTICS = getByEnv(null, 'UA-52976526-7');

export const MISSIVE_CHAT_ID = getByEnv(null, 'f5d1c774-19f4-4dcb-befe-6ff22a91ad11');

export const MISSIVE_CHAT_SECRET_KEY = getByEnv(null, 'ff6b398d7d79814a7322ce119862c500');

export const GOOGLE_TAG_MANAGER_KEY = getByEnv('GTM-WLLGNDNW', 'GTM-WLLGNDNW');
