import React, { useContext } from 'react';
import { Sidebar, ActionList, Button, Avatar, Icon, Tag } from '@trellixio/roaster-coffee';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { resolveUrl, Storage } from 'helpers';
import { Urls, Params } from 'data';
import { CardContext, AlertsContext } from 'context';
import { useAPI } from 'hooks';
import Locales from 'locales';
import beansLogo from 'assets/img/sidebar/beans-logo.svg';
import beansDropdownIcon from 'assets/img/sidebar/beans-dropdown-icon.svg';
import fcbkDropdownIcon from 'assets/img/sidebar/facebook-dropdown-icon.svg';
import homeIcon from 'assets/img/sidebar/home-icon.svg';
import membersIcon from 'assets/img/sidebar/members-icon.svg';
import integrationsIcon from 'assets/img/sidebar/integrations-icon.svg';
import settingsIcon from 'assets/img/sidebar/settings-icon.svg';
import analyticsIcon from 'assets/img/sidebar/analytics-icon.svg';
import rulesIcon from 'assets/img/sidebar/rules-icon.svg';
import referralsIcon from 'assets/img/sidebar/referrals-icon.svg';
import transactionsIcon from 'assets/img/sidebar/transactions-icon.svg';

const CONST_SHOPKEY = 'shopKey';

const SidebarComponent = () => {
  const { api } = useAPI();
  const { card } = useContext(CardContext);
  const { alertsCount, hasCriticalAlerts } = useContext(AlertsContext);
  const storage = new Storage(sessionStorage, 'boiler-');
  const shopKey = storage.get(CONST_SHOPKEY);
  const navigate = useNavigate();

  const { pathname: currentItemPath } = useLocation();
  const currentItemName = currentItemPath.replace(`/${shopKey}/`, '');

  const { data: user } = useQuery({
    queryFn: api.retrieve('core/user'),
    queryKey: ['core/user/current'],
  });

  const storeName = card?.company_name;

  const storeLogo = card?.logo;

  const userName = `${user?.first_name} ${user?.last_name}`;

  const isShopSelectPage = currentItemPath === Urls.select.card;

  const isFocus = (itemName) => currentItemName.includes(itemName);

  const menuItem = () => {
    const result = [];

    if (!isShopSelectPage) {
      const upperItems = [
        {
          icon: (
            <Icon small>
              <img src={homeIcon} alt={`${Locales.base.sidebar.link_to} ${Locales.base.sidebar.home}`} />
            </Icon>
          ),
          label: <span>{Locales.base.sidebar.home}</span>,
          active: isFocus('home') || currentItemName === '/',
          action: alertsCount ? (
            <Tag color={hasCriticalAlerts ? 'danger' : 'primary'} isIconOnly>
              {alertsCount}
            </Tag>
          ) : (
            ''
          ),
          onClick: () => navigate(resolveUrl(Urls.home.base)),
        },
        {
          icon: (
            <Icon small>
              <img src={membersIcon} alt={`${Locales.base.sidebar.link_to} ${Locales.base.sidebar.members.title}`} />
            </Icon>
          ),
          label: <span>{Locales.base.sidebar.members.title}</span>,
          active: isFocus('members') || isFocus('segments') || isFocus('tiers'),
          onClick: () => navigate(resolveUrl(Urls.members.base)), // We use 'onClick' instead of 'component' to navigate item with submenu
        },
        {
          icon: (
            <Icon small>
              <img src={rulesIcon} alt={`${Locales.base.sidebar.link_to} ${Locales.base.sidebar.rules}`} />
            </Icon>
          ),
          label: <span>{Locales.base.sidebar.rules}</span>,
          active: isFocus('rules'),
          onClick: () => navigate(resolveUrl(Urls.rules.base)),
        },
        {
          icon: (
            <Icon small>
              <img
                src={transactionsIcon}
                alt={`${Locales.base.sidebar.link_to} ${Locales.base.sidebar.transactions}`}
              />
            </Icon>
          ),
          label: Locales.base.sidebar.transactions,
          active: isFocus('transactions'),
          onClick: () => navigate(resolveUrl(Urls.transactions.base)),
        },
        {
          icon: (
            <Icon small>
              <img src={referralsIcon} alt={`${Locales.base.sidebar.link_to} ${Locales.base.sidebar.referrals}`} />
            </Icon>
          ),
          label: Locales.base.sidebar.referrals,
          active: isFocus('referrals'),
          onClick: () => navigate(resolveUrl(Urls.referrals.base)),
        },
        {
          icon: (
            <Icon small>
              <img src={analyticsIcon} alt={`${Locales.base.sidebar.link_to} ${Locales.base.sidebar.analytics}`} />
            </Icon>
          ),
          label: Locales.base.sidebar.analytics,
          active: isFocus('analytics'),
          onClick: () => navigate(resolveUrl(Urls.analytics.base)),
        },
      ];

      // add upperItems
      result.push(upperItems);

      // add Integrations
      result.push({
        icon: (
          <Icon small>
            <img src={integrationsIcon} alt={`${Locales.base.sidebar.link_to} ${Locales.base.sidebar.integrations}`} />
          </Icon>
        ),
        label: Locales.base.sidebar.integrations,
        active: isFocus('integrations'),
        onClick: () => navigate(resolveUrl(Urls.integrations.base)),
      });
    }

    return result;
  };

  return card && user ? (
    <Sidebar
      header={
        <>
          {!Params.IS_PROD_ENV && (
            <Button className="content-row" variant="primary">
              Staging
            </Button>
          )}

          <div className="app-logo">
            <img src={beansLogo} alt="beans-logo" title="beans logo" />
          </div>
        </>
      }
      mobileHeader={
        <div className="app-logo">
          <img src={beansLogo} alt="beans-logo" title="beans logo" />
        </div>
      }
      footer={
        <>
          <Sidebar.Menu>
            {!isShopSelectPage && (
              <Sidebar.Menu.Item
                icon={
                  <Icon small>
                    <img src={settingsIcon} alt={`${Locales.base.sidebar.link_to} ${Locales.base.sidebar.settings}`} />
                  </Icon>
                }
                active={isFocus('settings')}
                onClick={() => navigate(resolveUrl(Urls.settings.base))}
              >
                <span>{Locales.base.sidebar.settings}</span>
              </Sidebar.Menu.Item>
            )}
          </Sidebar.Menu>

          <ActionList>
            <ActionList.Trigger>
              <div className="store-profile button secondary" tabIndex="0" role="button">
                <div className="user">
                  <Avatar src={storeLogo} name={storeName} alt="user-logo" />
                  <div>
                    <p title={storeName}>{storeName}</p>
                    <span title={userName}>{userName}</span>
                  </div>
                </div>
                <i className="fa-regular fa-chevron-down" />
              </div>
            </ActionList.Trigger>
            <ActionList.Dropdown>
              <div className="user">
                <Avatar src={storeLogo} name={storeName} alt="user-logo" />
                <div>
                  <p title={storeName}>{storeName}</p>
                  <span title={userName}>{userName}</span>
                </div>
              </div>
              <ActionList.Group>
                <ActionList.Item>
                  <Link to={Urls.select.card} className="action-item">
                    <i className="fa-regular fa-store" />

                    <span>{Locales.base.sidebar.switch_store}</span>
                  </Link>
                </ActionList.Item>
                <ActionList.Item>
                  <Link to={resolveUrl(Urls.settings.account)} className="action-item">
                    <i className="fa-regular fa-user" />

                    <span>{Locales.base.sidebar.go_to_profile}</span>
                  </Link>
                </ActionList.Item>
                <ActionList.Item>
                  <Link to={Urls.front.logout} className="action-item danger">
                    <i className="fa-regular fa-arrow-right-from-bracket" />

                    <span>{Locales.base.sidebar.logout}</span>
                  </Link>
                </ActionList.Item>
              </ActionList.Group>
              <ActionList.Divider />
              <ActionList.Group>
                <ActionList.Item>
                  <a
                    href="https://docs.trybeans.com/"
                    className="action-item"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <i className="fa-regular fa-circle-info" />

                    <span>{Locales.base.sidebar.help_center}</span>
                  </a>
                </ActionList.Item>

                <ActionList.Item>
                  <a className="action-item" href="mailto:hello@trybeans.com">
                    <i className="fa-regular fa-circle-question" />
                    <span>{Locales.base.sidebar.support}</span>
                  </a>
                </ActionList.Item>

                <ActionList.Item>
                  <a
                    href="https://www.bns.re/blog"
                    className="action-item items-group"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <img src={beansDropdownIcon} alt="beans" />

                    <span>{Locales.base.sidebar.blog}</span>
                  </a>
                </ActionList.Item>
                <ActionList.Item>
                  <a
                    href="https://www.facebook.com/groups/1220975858059106/?_rdc=1&_rdr"
                    className="action-item items-group"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <img src={fcbkDropdownIcon} alt="facebook" />

                    <span>{Locales.base.sidebar.fcbk_group}</span>
                  </a>
                </ActionList.Item>
              </ActionList.Group>
            </ActionList.Dropdown>
          </ActionList>
        </>
      }
      items={menuItem()}
    />
  ) : null;
};

export default SidebarComponent;
