import { useContext } from 'react';
import { RestClientContext } from '../context/RestClientProvider'; // relative import to avoid circular dependency in context folder

/**
 * Initialize RestClientContext
 *
 * @returns {object} values contain in RestClientContext
 */
function useAPI() {
  return useContext(RestClientContext);
}

export default useAPI;
