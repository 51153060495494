/* eslint-disable */

import * as Sentry from '@sentry/browser';
import { HmacSHA256, enc } from 'crypto-js';
import { Params } from 'data';

function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

const isMobile =
  /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
    navigator.userAgent || window.opera
  );

const vendors = {
  _card: null,
  _user: null,
  _appName: null,
  _isLoaded: false,

  init(updatedCard = null, updatedUser = null) {
    this._appName = 'beans';
    this._card = updatedCard || {};
    this._user = updatedUser || {};

    this.Sentry.init();
    this.InspectLet.init();
    this.FBPixel.init();
    this.Amplitude.init();
    this.Missive.init();
    this.GA.init();

    this._isLoaded = true;

    window.vendors = this;
  },
  /* Missive Chat */
  Missive: {
    init: () => {
      window.MissiveChatConfig = {
        id: Params.MISSIVE_CHAT_ID,
        user: {
          name: `${vendors._user.first_name} ${vendors._user.last_name}`,
          email: vendors._user.email,
          digest: HmacSHA256(vendors._user.email, Params.MISSIVE_CHAT_SECRET_KEY).toString(enc.Hex),
        },
        chat: {
          position: isMobile ? 'bottom-left' : 'bottom-right',
        },
      };

      let s = document.createElement('script');
      s.async = true;
      s.src = `https://webchat.missiveapp.com/${Params.MISSIVE_CHAT_ID}/missive.js`;
      if (document.head) document.head.appendChild(s);
    },
    open: () => window.MissiveChat.open(),
  },
  /* Sentry Bug Reporting */
  Sentry: {
    init: function () {
      Sentry.init({
        dsn: Params.SENTRY_DSN,
        release: `${vendors._appName}`,
        environment: 'prod',
      });
    },
  },
  /* User session inspector */
  InspectLet: {
    init: function () {
      window.__insp = window.__insp || [];
      window.__insp.push(['wid', Params.INSPECTLET_ID]);
    },
  },
  /* Facebook Pixel */
  FBPixel: {
    _preload: function () {
      const w = window;
      let n;
      if (w.fbq) return;
      n = w.fbq = function () {
        if (n.callMethod) n.callMethod.apply(n, arguments);
        else n.queue.push(arguments);
      };
      if (!w._fbq) w._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
    },
    fbq: (...args) => {
      window.fbq(...args);
    },
    init() {
      this._preload();
      this.fbq('init', Params.FB_PIXEL_ID);
      this.fbq('trackCustom', 'AppView', { appName: vendors._appName });
    },
  },
  /* Google Analytics */
  GA: {
    gtag: function () {
      window.dataLayer.push(arguments);
    },
    init: function () {
      let path = window.location.pathname;
      path = path.replace(/acc_0[\w]+/g, ':uid');
      window.dataLayer = window.dataLayer || [];
      const data = {
        page_path: vendors._appName + path,
        user_id: vendors._user.public_id,
      };
      this.gtag('js', new Date());
      this.gtag('config', Params.GOOGLE_ANALYTICS, data);
    },
    pageview: function (path) {
      path = path || window.location.pathname;
      this.gtag('set', 'page', vendors._appName + path);
      this.gtag('send', 'pageview');
    },
  },
  /* Amplitude Analytics */
  Amplitude: {
    init: function () {
      this._load();
      this.getInstance().init(Params.AMPLITUDE_ID, vendors._user.id);

      const marketingSource = getParameterByName('s');
      const identity = new window.amplitude.Identify().set(`is_${vendors._appName}`, true);
      if (vendors._card.created) {
        identity
          .set(`${vendors._appName}:card_is_active`, vendors._card.is_active)
          .set(`${vendors._appName}:card_created`, vendors._card.created);
      }
      if (marketingSource) {
        identity.set('marketing_source_latest', marketingSource).setOnce('marketing_source_initial', marketingSource);
      }
      this.getInstance().identify(identity);
    },
    getInstance: () => window.amplitude.getInstance(),
    logEvent: function (event, properties = {}) {
      properties.app_name = vendors._appName;
      this.getInstance().logEvent(event, properties);
    },
    _load: function () {
      const e = window;
      if (e.amplitude) return;

      const n = { _q: [], _iq: {} };

      function s(f, t) {
        f.prototype[t] = function () {
          this._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          return this;
        };
      }

      const o = function () {
        this._q = [];
        return this;
      };
      const a = ['add', 'append', 'clearAll', 'prepend', 'set', 'setOnce', 'unset'];
      for (let u = 0; u < a.length; u += 1) s(o, a[u]);
      n.Identify = o;

      const c = function () {
        this._q = [];
        return this;
      };
      const l = ['setProductId', 'setQuantity', 'setPrice', 'setRevenueType', 'setEventProperties'];
      for (let p = 0; p < l.length; p += 1) s(c, l[p]);

      n.Revenue = c;
      const d = [
        'init',
        'logEvent',
        'logRevenue',
        'setUserId',
        'setUserProperties',
        'setOptOut',
        'setVersionName',
        'setDomain',
        'setDeviceId',
        'setGlobalUserProperties',
        'identify',
        'clearUserProperties',
        'setGroup',
        'logRevenueV2',
        'regenerateDeviceId',
        'groupIdentify',
        'onInit',
        'logEventWithTimestamp',
        'logEventWithGroups',
        'setSessionId',
        'resetSessionId',
      ];
      function v(f) {
        function t(tt) {
          f[tt] = function () {
            f._q.push([tt].concat(Array.prototype.slice.call(arguments, 0)));
          };
        }
        for (let nn = 0; nn < d.length; nn += 1) t(d[nn]);
      }
      v(n);

      n.getInstance = function (ee) {
        ee = (!ee || ee.length === 0 ? '$default_instance' : ee).toLowerCase();
        if (!n._iq.hasOwnProperty(ee)) {
          n._iq[ee] = { _q: [] };
          v(n._iq[ee]);
        }
        return n._iq[ee];
      };
      e.amplitude = n;
    },
  },
  /* Google Tag Manager */
  GTM: {
    init: () => {
      window.dataLayer = window.dataLayer || [];

      let s = document.createElement('script');
      s.async = true;
      s.src = `https://www.googletagmanager.com/gtm.js?id=${Params.GOOGLE_TAG_MANAGER_KEY}`;
      if (document.head) document.head.appendChild(s);
    },
    pageview: (path_) => {
      const path = path_ || window.location.pathname;
      window.dataLayer.push({
        event: 'pageview',
        page_path: path,
        page_title: path,
      });
    },
  },
};

export default vendors;
