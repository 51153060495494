import React, { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useAPI } from 'hooks';

export const AlertsContext = React.createContext({});

const AlertsProvider = ({ children }) => {
  const { api } = useAPI();
  const queryClient = useQueryClient();

  const { data: alerts } = useQuery({
    queryFn: api.list('ultimate/alert'),
    queryKey: ['ultimate/alert'],
    refetchInterval: 10 * 60 * 1000, // 10 minutes
  });

  const alertsCount = alerts?.count;

  const alertsData = alerts?.map((alert) => {
    const { id, message, cta, is_cancellable: isCancellable, level_str: level, link, code } = alert;

    const messageLocal = cta ? (
      <>
        <span>{message}</span>
        {'  '}
        {/* Link to activate app is open in same window */}
        <a href={link} target={code.includes('card_inactive') ? '' : '_blank'} rel="noopener noreferrer">
          {cta}
        </a>
      </>
    ) : (
      message
    );

    const alertLocal = { id, message: messageLocal, cta, isCancellable, level, link };
    return alertLocal;
  });

  const criticalAlerts = alertsData?.filter((alert) => alert.level === 'error');

  const hasCriticalAlerts = alertsData ? criticalAlerts.length > 0 : false;

  const { mutate: deleteAlert } = useMutation({
    mutationFn: (id) => api.remove('ultimate/alert', id),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['ultimate/alert'] }),
  });

  const memoValues = useMemo(() => ({ alerts: alertsData, alertsCount, hasCriticalAlerts, deleteAlert }), [alerts]);

  return <AlertsContext.Provider value={memoValues}>{children}</AlertsContext.Provider>;
};

AlertsProvider.defaultProps = {
  children: null,
};

AlertsProvider.propTypes = {
  children: PropTypes.node,
};

export default AlertsProvider;
