import React from 'react';
import { Icon } from '@trellixio/roaster-coffee';
import Locales from 'locales';
import error404Icon from 'assets/img/base/error-404-icon.svg';
import EmptyPage from './EmptyPage';

const ErrorPage = () => {
  return (
    <EmptyPage
      icon={
        <Icon>
          <img src={error404Icon} alt="error" />
        </Icon>
      }
    >
      <h3 className="group-label">{Locales.base.error_page.not_found_title}</h3>
      <p>{Locales.base.error_page.not_found_description}</p>
    </EmptyPage>
  );
};

export default ErrorPage;
