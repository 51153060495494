import React from 'react';
import { Modal, Icon, Button } from '@trellixio/roaster-coffee';
import { Params } from 'data';
import Locales from 'locales';
import PropTypes from 'prop-types';

const UpgradeProModal = ({ opened, onClose }) => {
  return (
    <Modal title={Locales.base.upgrade_pro.title} opened={opened} onClose={onClose}>
      <Icon color="primary">
        <i className="fa-solid fa-lock" />
      </Icon>

      <p className="content-row" />

      <h4>{Locales.base.upgrade_pro.feature_available}</h4>
      <p className="content-row">{Locales.base.upgrade_pro.description}</p>

      <a href={`${Params.CONNECT_BASE_URL}/billing/upgrade/ultimate/`} target="_blank" rel="noopener noreferrer">
        <Button>{Locales.base.upgrade_pro.view_options}</Button>
      </a>
    </Modal>
  );
};

UpgradeProModal.defaultProps = {
  opened: false,
  onClose: null,
};

UpgradeProModal.propTypes = {
  opened: PropTypes.bool,
  onClose: PropTypes.func,
};

export default UpgradeProModal;
