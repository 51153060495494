import React, { useMemo } from 'react';
import { showToast } from '@trellixio/roaster-coffee';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import Locales from 'locales';
import { useAPI } from 'hooks';

export const UserContext = React.createContext({});

const UserProvider = ({ children }) => {
  const { api } = useAPI();
  const queryClient = useQueryClient();

  const { data: user } = useQuery({
    queryFn: api.retrieve('core/user'),
    queryKey: ['core/user/current'],
  });

  const { mutate: update } = useMutation({
    mutationFn: (updatedUser) => api.update('core/user', { ...user, ...updatedUser }),
    onSuccess: ({ data }) => {
      queryClient.setQueryData(['core/user/current'], data);
      showToast({ message: Locales.base.toasts.success_update, level: 'success' });
    },
  });

  const memoValues = useMemo(() => ({ user, update }), [user]);

  return <UserContext.Provider value={memoValues}>{children}</UserContext.Provider>;
};

UserProvider.defaultProps = {
  children: null,
};

UserProvider.propTypes = {
  children: PropTypes.node,
};

export default UserProvider;
