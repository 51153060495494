import { generatePath } from 'react-router-dom';
import moment from 'moment';
import { Params, Urls } from 'data';
import Storage from './Storage';

const storage = new Storage(sessionStorage, 'boiler-');
const CONST_USER_TOKEN = 'user_token';
const CONST_SHOPKEY = 'shopKey';

/**
 * Logout action
 */
export const onLogout = () => {
  storage.clear();
  window.location.href = `${Params.CONNECT_BASE_URL}/auth/logout/`;
};

/**
 * Set the current card key in the URL
 *
 * @param {string} url input URL
 * @returns {string} URL with current card key set
 */
export const resolveUrl = (url) => {
  // if user is connect, we fetch the shopKey from storage
  const shopKey = storage.get(CONST_USER_TOKEN) ? storage.get(CONST_SHOPKEY) : '';

  return generatePath(url, { shopKey });
};

/**
 * Concat a user firstname and lastname together
 *
 * @param {string} firstname user firstname
 * @param {string} lastname user lastname
 * @returns {string} concatanation of firstname and lastname
 */
export const getUsername = (firstname, lastname) => `${firstname} ${lastname}`;

/**
 * Formate a date using moment js
 *
 * @param {string} date a date to formate
 * @param {string} format the expect format for the date
 * @returns {string} a date to the expected format
 */
export const formatDate = (date, format = 'MMM D, YYYY') => moment(date).format(format);

/**
 * Check if link is an URL with parameter
 *
 * @param {string} link an URL pattern
 * @returns {boolean} TRUE if the link have a parameter
 */
export const isURLWithParams = (link) => link.includes('/:id') || link.includes('/:uid');

/**
 * Transform the Urls object in an iterable array
 *
 * @returns {Array} an array of all the project URLs pattern
 */
export const getAllURLsPattern = () => {
  const results = [];
  const paramLinks = [];

  Object.values(Urls).forEach((firstChild) => {
    Object.values(firstChild).forEach((secondChild) => {
      if (typeof secondChild === 'object') {
        Object.values(secondChild).forEach((thirdChild) => {
          if (isURLWithParams(thirdChild)) paramLinks.push(thirdChild);
          else results.push(thirdChild);
        });
      } else if (isURLWithParams(secondChild)) {
        paramLinks.push(secondChild);
      } else results.push(secondChild);
    });
  });

  // URLs such as /login or /logout are excluded from the list
  return results.concat(paramLinks).filter((link) => link.includes('/:shopKey'));
};

/**
 * Login a merchant based on the shopKey. Only useful for merchant that handle mutliple shops
 *
 * @param {string} shopKey a unique key representing a merchant shop. Can be found in card.address
 * @param {string} forwardedURL a string indicating the URL where the user should be redirect after login
 */
export const logInWithShopKey = (shopKey, forwardedURL) => {
  const redirectURI = encodeURIComponent(`${window.location.origin}/login?next=${forwardedURL}`);

  window.location.href = `${Params.CONNECT_BASE_URL}/oauth/${shopKey}/boiler/authorize/?redirect_uri=${redirectURI}`;
};

/**
 * Retrieve the value of the search parameter cursor from an url
 *
 * @param {string} url an url that was retrieve from a LIST query
 * @returns {string} the cursor parameter
 */
export const getQueryCursorFromURL = (url) => {
  const params = new URL(url).searchParams;
  return params.get('cursor');
};

/**
 * Update the value of the cursor set in the LIST query. This allow the user to go from one page to another
 *
 * @param {string} url an url that was retrieve from a LIST query
 * @param {Function} setCursor a react method to update a state value
 * @returns {Function} method to update state with data from url
 */
export const updateQueryCursor = (url, setCursor) => setCursor(getQueryCursorFromURL(url));

/**
 *
 * @param {object} e the keydown event of the input
 */
export const preventSubmitWithENTER = (e) => {
  if (e.keyCode === 13) {
    e.preventDefault(); // prevent ENTER from submitting the form, thus reloading the page
  }
};

/**
 * Make the first letter of a string capitalize
 *
 * @param {string} string a regular string
 * @returns {string} the same string with the first letter capitalize
 */
export const capitalizeFirstLetter = (string) => string[0].toUpperCase() + string.slice(1);
