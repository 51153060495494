import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { ToastsProvider, showToast } from '@trellixio/roaster-coffee';
import { QueryClient, QueryClientProvider, MutationCache } from '@tanstack/react-query';
import { AlertsProvider, RestClientProvider, CardProvider, MerchantPlanProvider, UserProvider } from 'context';
import { ErrorBoundary } from 'components';
import { Storage } from 'helpers';
import App from 'App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

const CONST_USER_TOKEN = 'user_token';
const storage = new Storage(sessionStorage, 'boiler-');
const token = storage.get(CONST_USER_TOKEN);

const mutationCache = new MutationCache({
  onError: (error, _variables, _context, mutation) => {
    // By default, display error message as a toast when useMutation fail
    // Don't apply in case the useMutation already have a onError handler
    if (!mutation.options.onError) {
      showToast({ message: error?.message, level: 'error' });
    }
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60 * 60 * 1000, // 1 hour
      cacheTime: 48 * 60 * 60 * 1000, // 48 hours
      enabled: !!token, // queries are fire only when user_token exist
    },
  },
  mutationCache,
});

ReactDOM.createRoot(document.getElementById('root')).render(
  <BrowserRouter>
    <ToastsProvider>
      <RestClientProvider>
        <QueryClientProvider client={queryClient}>
          <AlertsProvider>
            <UserProvider>
              <CardProvider>
                <MerchantPlanProvider>
                  <ErrorBoundary>
                    <App />
                  </ErrorBoundary>
                </MerchantPlanProvider>
              </CardProvider>
            </UserProvider>
          </AlertsProvider>
        </QueryClientProvider>
      </RestClientProvider>
    </ToastsProvider>
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
