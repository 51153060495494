import React, { useContext } from 'react';
import { Navigate, Outlet, useParams, useLocation, useMatch, generatePath } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Urls } from 'data';
import { Storage, getAllURLsPattern, logInWithShopKey, vendors } from 'helpers';
import { CardContext, MerchantPlanContext } from 'context';

const CONST_SHOPKEY = 'shopKey';

const ProtectedRoute = ({ isAllowed, children }) => {
  const storage = new Storage(sessionStorage, 'boiler-');
  const shopKey = storage.get(CONST_SHOPKEY);
  const { card, availableShopKeys } = useContext(CardContext);
  const { planPackage, isProMerchant, trialDaysLeft, isDelinquent } = useContext(MerchantPlanContext);
  const { search, pathname } = useLocation();
  const params = useParams();
  const { shopKey: inputKey } = params;
  const isCurrentShopKey = shopKey === inputKey;
  const isAuthorizeShopKey = availableShopKeys?.includes(inputKey);
  const linkWithoutShopKey = ['/', Urls.select.card];

  if (!isAllowed) {
    // Redirect to the login page if the user is not allowed to see
    // the page he is trying to access
    // store page URL in state referrer
    return <Navigate to={Urls.front.login.base} state={{ referrer: window.location.href }} replace />;
  }

  if (planPackage && isDelinquent) {
    // Redirect to update payment page
    return <Navigate to={Urls.flag.delinquency} replace />;
  }

  if (planPackage && !isProMerchant && !trialDaysLeft) {
    // Redirect to TrialOver page
    return <Navigate to={Urls.flag.trial_over} replace />;
  }

  if (card && !card.storage.is_onboarded) {
    // Redirect to Beans Onboarding
    return <Navigate to={generatePath(Urls.welcome.base, { shopKey })} replace />;
  }

  // if there is no shopKey we redirect to the same link while adding current card shopKey
  // some pages do not need the shopKey, we exclude them
  if (!inputKey && !linkWithoutShopKey.includes(pathname)) {
    return <Navigate to={`/${shopKey}${pathname}${search}`} replace />;
  }

  // if the user input a shopKey other than the one for the shop currently connected
  if (!isCurrentShopKey && !linkWithoutShopKey.includes(pathname)) {
    const allURLs = getAllURLsPattern();
    const matchPath = allURLs.filter((url) => useMatch(url));
    const currentPathPattern = matchPath.length > 0 ? matchPath[0] : null;

    // We extract the path where the user was located
    const correctPath = currentPathPattern
      ? `${generatePath(currentPathPattern, { ...params, shopKey: '' })}${search}`
      : 'notFound';

    if (isAuthorizeShopKey) {
      // If the input shopKey is authorize
      // meaning it's in list of the known shopKeys for this user
      // we directly log the user to the corresponding shop
      logInWithShopKey(inputKey, correctPath);
    } else {
      // If the input shopKey is not authorize
      // we redirect the user to the shop selection page
      return <Navigate to={Urls.select.card} state={{ referrer: correctPath }} replace />;
    }
  }

  const displayedPage = () => {
    // eslint-disable-next-line no-underscore-dangle
    if (vendors._isLoaded) {
      vendors.GA.pageview(pathname);
    }

    return children || <Outlet />;
  };

  return displayedPage(); // Display the page if the user is allowed
};

ProtectedRoute.defaultProps = {
  isAllowed: false,
  children: null,
};

ProtectedRoute.propTypes = {
  isAllowed: PropTypes.bool,
  children: PropTypes.node,
};

export default ProtectedRoute;
