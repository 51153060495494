import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { showToast } from '@trellixio/roaster-coffee';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Locales from 'locales';
import { useAPI } from 'hooks';
import { Storage, vendors } from 'helpers';

const CONST_CARDS_LIST = 'cards_list';

export const CardContext = React.createContext({});

const CardProvider = ({ children }) => {
  const { api } = useAPI();
  const queryClient = useQueryClient();
  const storage = new Storage(sessionStorage, 'boiler-');
  const listCards = JSON.parse(storage.get(CONST_CARDS_LIST));

  const { data: card } = useQuery({
    queryFn: api.retrieve('ultimate/card'),
    queryKey: ['ultimate/card/current'],
  });

  const onUpdateSuccess = (data) => {
    queryClient.setQueryData(['ultimate/card/current'], data);
    showToast({ message: Locales.base.toasts.success_update, level: 'success' });
  };

  const { mutate: updateLogo } = useMutation({
    mutationFn: (file) => {
      if (!file) return 0;

      if (file.size / 1024 >= 1024) {
        showToast({ message: Locales.base.toasts.error_file_size, level: 'error' });
        return 0;
      }

      return api.updateFile('ultimate/card', 'logo', [file]);
    },
    onSuccess: (data) => {
      if (data) {
        onUpdateSuccess(data.data);
      }
    },
  });

  const { mutate: save } = useMutation({
    mutationFn: (updatedCard) => api.update('ultimate/card', updatedCard),
    onSuccess: ({ data }, updatedCard) => {
      const { logo } = updatedCard;
      if (logo) {
        updateLogo(logo);
      } else {
        onUpdateSuccess(data);
      }
    },
  });

  const { mutate: activate } = useMutation({
    mutationFn: (appName) => api.post(`${appName}/card/current/activate`, card),
    onSuccess: (data, appName) => {
      queryClient.invalidateQueries({ queryKey: ['ultimate/card/current'] });
      queryClient.invalidateQueries({ queryKey: ['ultimate/alert'] }); // refetch alerts
      showToast({
        message: Locales.formatString(Locales.base.toasts.status_online, {
          appTitle: Locales.base.apps[appName].headline,
        }),
        level: 'success',
      });
      vendors.Amplitude.logEvent(`Activate ${appName}`, { origin: 'sidebar' });
    },
  });

  const { mutate: deactivate } = useMutation({
    mutationFn: (appName) => api.delete(`${appName}/card/current/activate`),
    onSuccess: (data, appName) => {
      queryClient.invalidateQueries({ queryKey: ['ultimate/card/current'] });
      queryClient.invalidateQueries({ queryKey: ['ultimate/alert'] }); // refetch alerts
      showToast({
        message: Locales.formatString(Locales.base.toasts.status_offline, {
          appTitle: Locales.base.apps[appName].headline,
        }),
        level: 'error',
      });
      vendors.Amplitude.logEvent(`Deactivate ${appName}`, { origin: 'sidebar' });
    },
  });

  const { mutate: updateInstalledApps } = useMutation({
    mutationFn: (appsToInstall) => api.post('ultimate/card/current/install', { app_names: appsToInstall }),
    onSuccess: ({ data }, appsToInstall) => {
      queryClient.setQueryData(['ultimate/card/current'], data);
      appsToInstall.forEach((appName) =>
        showToast({
          message: Locales.formatString(Locales.base.toasts.activated_success, {
            appTitle: Locales.base.apps[appName].headline,
          }),
          level: 'success',
        })
      );
    },
  });

  const availableCards = listCards?.map((cardData) => cardData.metacard);

  const availableShopKeys = availableCards?.map((availableCard) => availableCard.address);

  const cardProviderValues = useMemo(
    () => ({
      card,
      save,
      activate,
      deactivate,
      updateLogo,
      updateInstalledApps,
      availableCards,
      availableShopKeys,
    }),
    [card, listCards]
  );

  return <CardContext.Provider value={cardProviderValues}>{children}</CardContext.Provider>;
};

CardProvider.defaultProps = {
  children: null,
};

CardProvider.propTypes = {
  children: PropTypes.node,
};

export default CardProvider;
