import React from 'react';
import PropTypes from 'prop-types';
import styles from './EmptyPage.module.css';

const EmptyPage = ({ icon, children }) => {
  return (
    <article className={styles.container}>
      <div>{icon}</div>

      <div className={styles.content}>{children}</div>
    </article>
  );
};

EmptyPage.defaultProps = {
  icon: null,
  children: null,
};

EmptyPage.propTypes = {
  icon: PropTypes.string,
  children: PropTypes.node,
};

export default EmptyPage;
