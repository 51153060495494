import React from 'react';
import PropTypes from 'prop-types';
import styles from './EmptyTable.module.css';

const EmptyTable = ({ label }) => {
  return (
    <article className={styles.container}>
      <p>{label}</p>
    </article>
  );
};

EmptyTable.defaultProps = {
  label: null,
};

EmptyTable.propTypes = {
  label: PropTypes.string,
};

export default EmptyTable;
