import React, { useState } from 'react';
import { ActionCard, Button, Icon } from '@trellixio/roaster-coffee';
import Locales from 'locales';
import PropTypes from 'prop-types';
import UpgradeEnterpriseModal from './UpgradeEnterpriseModal';
import UpgradeProModal from './UpgradeProModal';
import styles from './UpgradePlan.module.css';

const UpgradePlanCard = ({ children, showProModal }) => {
  const [upgradeEnterpriseModalOpened, setUpgradeEnterpriseModalOpened] = useState(false);
  const [upgradeProModalOpened, setUpgradeProModalOpened] = useState(false);

  const toggleUpgradeEnterpriseModalOpen = () => setUpgradeEnterpriseModalOpened((val) => !val);

  const toggleUpgradeProModalOpen = () => setUpgradeProModalOpened((val) => !val);

  const toggleUpgradeModalOpen = () =>
    showProModal ? toggleUpgradeProModalOpen() : toggleUpgradeEnterpriseModalOpen();

  return (
    <>
      <ActionCard
        className={styles.card}
        icon={
          <Icon color="primary">
            <i className="fa-solid fa-lock" />
          </Icon>
        }
        actions={<Button onClick={toggleUpgradeModalOpen}>{Locales.base.button.upgrade_now}</Button>}
      >
        {children}
      </ActionCard>

      {upgradeProModalOpened && <UpgradeProModal opened={upgradeProModalOpened} onClose={toggleUpgradeProModalOpen} />}

      {upgradeEnterpriseModalOpened && (
        <UpgradeEnterpriseModal opened={upgradeEnterpriseModalOpened} onClose={toggleUpgradeEnterpriseModalOpen} />
      )}
    </>
  );
};

UpgradePlanCard.defaultProps = {
  children: null,
  showProModal: false,
};

UpgradePlanCard.propTypes = {
  children: PropTypes.node,
  showProModal: PropTypes.bool,
};

export default UpgradePlanCard;
