import React, { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useAPI } from 'hooks';

export const MerchantPlanContext = React.createContext({});

const MerchantPlanProvider = ({ children }) => {
  const { api } = useAPI();

  const { data: planPackage } = useQuery({
    queryFn: api.retrieve('ultimate/package'),
    queryKey: ['ultimate/package'],
  });

  const isEnterprise = planPackage?.is_enterprise;

  const isProMerchant = planPackage?.is_pro;

  const trialDaysLeft = planPackage?.trial_days_left;

  const isDelinquent = planPackage?.is_delinquent;

  const memoValues = useMemo(
    () => ({ planPackage, isEnterprise, isProMerchant, trialDaysLeft, isDelinquent }),
    [planPackage]
  );

  return <MerchantPlanContext.Provider value={memoValues}>{children}</MerchantPlanContext.Provider>;
};

MerchantPlanProvider.defaultProps = {
  children: null,
};

MerchantPlanProvider.propTypes = {
  children: PropTypes.node,
};

export default MerchantPlanProvider;
