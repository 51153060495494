// eslint-disable-next-line max-classes-per-file
class ExtendableError extends Error {
  constructor(message, fieldMessages) {
    super(message);
    this.name = this.constructor.name;
    this.fieldMessages = fieldMessages || {};
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(message).stack;
    }
  }
}

export class APIError extends ExtendableError {}

export class APIError400BadRequest extends APIError {}
export class APIError402PaymentRequired extends APIError {}
export class APIError403Forbidden extends APIError {}
export class APIError404NotFound extends APIError {}
export class APIError409Conflict extends APIError {}
export class APIError424FailedDependency extends APIError {}
export class APIError50XServerError extends APIError {}

/**
 *
 * @param {object} apiError object return by a fail API call
 * @returns {APIError400BadRequest} instance of APIError400BadRequest
 */
function getException400BadRequest(apiError) {
  const errorType = typeof apiError.message;

  if (errorType === 'string') {
    return new APIError400BadRequest(apiError.message);
  }
  /**
   * error message is a dictionary, example:
   * {
   *   "message": {
   *      "link": ["Enter a valid URL."]
   *    },
   *    "code": 400
   * }
   */
  const errorList = [];
  const fieldMessages = {};
  Object.keys(apiError.message).forEach((key) => {
    const value = apiError.message[key][0];
    errorList.push(value);
    fieldMessages[key] = value;
  });
  return new APIError400BadRequest(errorList[0], fieldMessages);
}

/**
 *
 * @param {object} apiError object return by a fail API call
 * @param {Function} onLogout Logout function
 * @returns {Promise} a Promise
 */
export async function raiseAPIError(apiError, onLogout) {
  let exception;
  switch (apiError.code) {
    case 401:
      return onLogout();
    case 400:
      exception = getException400BadRequest(apiError);
      break;
    case 402:
      exception = new APIError402PaymentRequired(apiError.message);
      break;
    case 403:
      exception = new APIError403Forbidden(apiError.message);
      break;
    case 404:
      exception = new APIError404NotFound(apiError.message);
      break;
    case 409:
      exception = new APIError409Conflict(apiError.message);
      break;
    case 424:
      exception = new APIError424FailedDependency(apiError.message);
      break;
    case 500:
    case 503:
      exception = new APIError50XServerError(apiError.message);
      break;
    default:
      exception = new APIError(apiError.message);
      break;
  }
  return Promise.reject(exception);
}
