import Locales from 'locales';
/* ---------------------------------------------
   Industries represents which type of products
   a merchant is selling.
--------------------------------------------- */

const Industries = [
  { value: null, label: 'none' },
  { value: 'adult', label: Locales.industries.adult },
  { value: 'apparel', label: Locales.industries.apparel },
  { value: 'beauty_cosmetics', label: Locales.industries.beauty },
  { value: 'books_music_education', label: Locales.industries.books },
  { value: 'collectibles', label: Locales.industries.collectibles },
  { value: 'electronics', label: Locales.industries.electronics },
  { value: 'food', label: Locales.industries.food },
  { value: 'general_goods', label: Locales.industries.goods },
  { value: 'health_nutrition', label: Locales.industries.health },
  { value: 'hobbies_toys', label: Locales.industries.hobbies },
  { value: 'home_leisure', label: Locales.industries.home },
  { value: 'jewelry', label: Locales.industries.jewelry },
  { value: 'mother_baby', label: Locales.industries.mother },
  { value: 'men_grooming', label: Locales.industries.men },
  { value: 'party_and_special_occasions', label: Locales.industries.party },
  { value: 'pets', label: Locales.industries.pets },
  { value: 'services', label: Locales.industries.services },
  { value: 'sport', label: Locales.industries.sport },
  { value: 'stationery_office_supplies', label: Locales.industries.stationery },
  { value: 'vaping_ecig', label: Locales.industries.vaping },
];

export default Industries;
