const getColorsVariable = (variable) => getComputedStyle(document.documentElement).getPropertyValue(variable);

export const colors = () => {
  return {
    primary: getColorsVariable('--color-primary'),
    info: getColorsVariable('--color-info'),
    success: getColorsVariable('--color-success'),
    grey: getColorsVariable('--color-grey'),
    textBase: getColorsVariable('--color-text-base'),
    warning: getColorsVariable('--color-warning'),
    danger: getColorsVariable('--color-danger'),
  };
};

/**
 * custom tooltip params
 */
export const tooltip = {
  backgroundColor: 'white',
  borderWidth: 1,
  borderColor: `rgba(${colors().grey} / 40% )`,
  textStyle: {
    fontFamily: 'DM Sans',
    color: `rgba(${colors().textBase})`,
  },
  padding: 8,
  extraCssText: 'box-shadow: 0 3px 6px -3px rgb(0 0 0 / 8%), 0 8px 20px -4px rgb(0 0 0 / 12%); border-radius: 4px;',
};

export const grid = { show: true, top: 30, right: 8, bottom: 24, left: 36 };

/**
 *  Chart axes options
 */
export const axes = {
  /**
   * xAxis option settings
   *
   * @param {boolean} boundaryGap The boundary gap on both sides of a coordinate axis.
   * @returns {object} xAxis attributes
   */
  xAxis(boundaryGap = false) {
    return {
      type: 'category',
      boundaryGap,
      nameTextStyle: {
        color: `rgba(${colors().textBase})`,
      },
      nameLocation: 'middle',
      nameGap: 30,
      splitLine: {
        show: true,
        lineStyle: {
          color: `rgba(${colors().grey} / 40% )`,
        },
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: `rgba(${colors().grey})`,
          width: 1.3,
        },
      },
      axisTick: {
        show: true,
        alignWithLabel: true,
        lineStyle: {
          color: `rgba(${colors().grey})`,
          width: 1.3,
        },
      },
    };
  },

  /**
   * yAxis option settings
   *
   * @returns {object} yAxis attributes
   */
  yAxis() {
    return {
      type: 'value',
      nameTextStyle: {
        color: `rgba(${colors().textBase})`,
      },

      splitLine: {
        show: true,
        lineStyle: {
          color: `rgba(${colors().grey} / 40% )`,
        },
      },
      axisLabel: {
        show: true,
        color: `rgba(${colors().grey})`,
      },
      axisTick: {
        show: true,
        lineStyle: {
          color: `rgba(${colors().grey})`,
          width: 1.3,
        },
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: `rgba(${colors().grey})`,
          width: 1.3,
        },
      },
    };
  },
};
