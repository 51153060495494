import React from 'react';
import { Select, TextField, FileField, Textarea, ColorField, Switch } from '@trellixio/roaster-coffee';
import PropTypes from 'prop-types';

const SettingField = ({ previousValue, field, handleSettingsChange }) => {
  const { label, help_text: helpText, type, choices, default: defaultValue, name } = field;
  const selectChoices = choices?.map((choice) => {
    const [value, choiceLabel] = choice;
    const choiceLocal = { value, label: choiceLabel };
    return choiceLocal;
  });

  const handleChange = (value) => {
    const newValue = typeof defaultValue === 'number' ? parseInt(value, 10) : value;
    const valueLocal = defaultValue === 0 || defaultValue ? newValue : value;
    handleSettingsChange(name, valueLocal);
  };

  const selectedField = () => {
    let input = null;
    switch (type) {
      case 'integer':
      case 'text':
        input = (
          <TextField
            label={label}
            type={type === 'integer' ? 'number' : 'text'}
            helpText={helpText}
            defaultValue={previousValue}
            onChange={(value) => handleChange(value)}
          />
        );
        break;
      case 'boolean':
        input = (
          <Switch
            defaultChecked={previousValue}
            label={label}
            helpText={helpText}
            onChange={(value) => handleChange(value)}
          />
        );
        break;

      case 'choice':
        input = (
          <Select
            label={label}
            helpText={helpText}
            data={selectChoices}
            defaultValue={previousValue}
            onChange={(value) => handleChange(value)}
          />
        );
        break;

      case 'textarea':
        input = (
          <Textarea
            label={label}
            helpText={helpText}
            defaultValue={previousValue}
            onChange={(value) => handleChange(value)}
          />
        );
        break;

      case 'image':
        input = (
          <FileField
            label={label}
            helpText={helpText}
            defaultValue={previousValue}
            onChange={(value) => handleChange(value)}
          />
        );
        break;

      case 'color':
        input = (
          <ColorField
            label={label}
            helpText={helpText}
            defaultValue={previousValue}
            onChange={(value) => handleChange(value)}
          />
        );
        break;

      default:
        break;
    }
    return input;
  };

  return selectedField();
};

SettingField.defaultProps = {
  previousValue: null,
  field: null,
  handleSettingsChange: null,
};

SettingField.propTypes = {
  previousValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  field: PropTypes.shape({
    label: PropTypes.string,
    help_text: PropTypes.string,
    type: PropTypes.string,
    choices: PropTypes.string,
    default: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  }),
  handleSettingsChange: PropTypes.func,
};

export default SettingField;
