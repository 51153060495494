import LocalizedStrings from 'react-localization';
import industries from './industries.json';
import base from './base.json';
import home from './pages/home.json';
import onboarding from './pages/onboarding.json';
import segments from './pages/segments.json';
import members from './pages/members.json';
import settings from './pages/settings.json';
import referrals from './pages/referrals.json';
import transactions from './pages/transactions.json';
import tiers from './pages/tiers.json';
import rules from './pages/rules.json';
import analytics from './pages/analytics.json';

const en = {
  industries,
  base,
  home,
  onboarding,
  segments,
  members,
  settings,
  referrals,
  transactions,
  tiers,
  rules,
  analytics,
};

const Locales = new LocalizedStrings({ en });

export default Locales;
