import { useState, useEffect, useRef } from 'react';

/**
 * Delay state update and reduces unnecessary computations to ensure that operations are only performed after a pause in the input activity
 *
 * @param {any} value The value to debounce
 * @param {number} delay The delay time in milliseconds. After this amount of time, the latest value is used.
 * @returns {string} The debounced value. After the delay time has passed without the value changing, this will be updated to the latest value.
 */
function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const timerRef = useRef();

  useEffect(() => {
    timerRef.current = setTimeout(() => setDebouncedValue(value), delay || 1000);

    return () => {
      clearTimeout(timerRef.current);
    };
  }, [value, delay]);

  return debouncedValue;
}

export default useDebounce;
