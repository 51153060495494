import React from 'react';
import { Modal, Button, Icon } from '@trellixio/roaster-coffee';
import { Params } from 'data';
import Locales from 'locales';
import PropTypes from 'prop-types';
import upgradeIcon from 'assets/img/base/upgrade-plan-icon.svg';
import styles from './UpgradePlan.module.css';

const UpgradeEnterpriseModal = ({ opened, onClose }) => {
  const advantages = [
    Locales.base.upgrade_enterprise.unlimited_orders,
    Locales.base.upgrade_enterprise.buy_products,
    Locales.base.upgrade_enterprise.tiers,
    Locales.base.upgrade_enterprise.custom_page,
    Locales.base.upgrade_enterprise.support,
  ];
  return (
    <Modal
      title={Locales.base.upgrade_enterprise.title}
      size="large"
      className={styles.wrapper}
      opened={opened}
      onClose={onClose}
    >
      <div className={styles.container}>
        <div className={styles.textContainer}>
          <h2>{Locales.base.upgrade_enterprise.do_more}</h2>
          <p className="content-row">{Locales.base.upgrade_enterprise.description}</p>

          <div className={styles.rowContainer}>
            {advantages.map((advantage) => (
              <div className={`${styles.row} items-group`}>
                <Icon color="success" small>
                  <i className="fa-regular fa-check" />
                </Icon>

                <span>{advantage}</span>
              </div>
            ))}
          </div>
          <a href={`${Params.CONNECT_BASE_URL}/billing/upgrade/ultimate/`} target="_blank" rel="noopener noreferrer">
            <Button>{Locales.base.upgrade_enterprise.view_options}</Button>
          </a>
        </div>
        <div className={styles.imgContainer}>
          <img src={upgradeIcon} alt="upgrade" />
        </div>
      </div>
    </Modal>
  );
};

UpgradeEnterpriseModal.defaultProps = {
  opened: false,
  onClose: null,
};

UpgradeEnterpriseModal.propTypes = {
  opened: PropTypes.bool,
  onClose: PropTypes.func,
};

export default UpgradeEnterpriseModal;
