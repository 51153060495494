import React, { useContext } from 'react';
import { Page, ActionCard, Icon, Button, Tag } from '@trellixio/roaster-coffee';
import { Link, useLocation } from 'react-router-dom';
import { logInWithShopKey, Storage } from 'helpers';
import { CardContext } from 'context';
import Locales from 'locales';

const CONST_SHOPKEY = 'shopKey';

const SelectCard = () => {
  const storage = new Storage(sessionStorage, 'boiler-');
  const shopKey = storage.get(CONST_SHOPKEY);
  const { availableCards } = useContext(CardContext);
  const { state } = useLocation();
  const referrer = state ? state.referrer : null;
  const forwardedURL = referrer || window.location.origin;

  return (
    <Page title={Locales.base.stores.title}>
      <h3>{Locales.base.stores.description}</h3>

      {availableCards?.map((card) => (
        <ActionCard
          key={card.address}
          icon={
            <Icon>
              <img src={card.logo} alt={card.logo} />
            </Icon>
          }
          actions={
            shopKey === card.address ? (
              <Link to={forwardedURL}>
                <Button variant="secondary">{Locales.base.button.select}</Button>
              </Link>
            ) : (
              <Button variant="secondary" onClick={() => logInWithShopKey(card.address, forwardedURL)}>
                {Locales.base.button.select}
              </Button>
            )
          }
        >
          <div className="items-group">
            <h4>{card.company_name}</h4>

            {shopKey === card.address && <Tag color="success">{Locales.base.button.current}</Tag>}
          </div>

          <p>{card.website}</p>
        </ActionCard>
      ))}
    </Page>
  );
};

export default SelectCard;
